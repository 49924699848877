import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Button, CardMedia } from "@mui/material";
import AddProductToCategoryModal from "./modals/AddProductToCategoryModal";
import { deleteFromMenu } from "../actions/actionCreator";
import Product from "./Product";
import ProductModal from "./modals/ProductModal";
import CategoryEditModal from "./modals/CategoryEditModal";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { updateSortableProducts } from "../actions/actionCreator";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { toggleSideMenu } from "../actions/actionCreator";

const Category = ({ category, sort, index }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const ref = useRef(null);
  const { menu, toggle } = useSelector((state) => state.menu);
  const [characters, updateCharacters] = useState(category.products);

  const [expanded, setExpanded] = React.useState(false);

  const handleScroll = () => {
    ref.current?.scrollIntoView({ alignToTop: true });
  };

  const sortAlert = () => {
    alert("Enable sort before using it");
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateCharacters(items);
  }

  const handleDelete = () => {
    const newMenu = [...menu];
    const index = newMenu.findIndex((item) => item.id === category.id);
    newMenu.splice(index, 1);
    dispatch(deleteFromMenu(newMenu));
  };

  const renderProducts = () => {
    return characters.map((product, i) => {
      if (location.pathname === "/panel") {
        return (
          <Draggable key={i} draggableId={i.toString()} index={i}>
            {(provided) => (
              <Box
                sx={{
                  width: sort ? "100%" : "24%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                key={i.toString()}
              >
                <Product
                  category={category}
                  product={product}
                  key={i}
                  i={i}
                  sort={sort}
                />
              </Box>
            )}
          </Draggable>
        );
      } else {
        return (
          <ProductModal category={category} product={product} key={i} i={i} />
        );
      }
    });
  };

  const submit = () => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(),
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };

  const handleChange = (panel) => async (event, isExpanded) => {
    dispatch(toggleSideMenu(false));
    setExpanded(isExpanded ? panel : false);
    //dispatch(toggleSideMenu(category.id));
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth", alignToTop: true });
    }, 600);
  };

  useEffect(() => {
    const updateItem = {
      id: category.id,
      products: characters,
    };
    dispatch(updateSortableProducts(updateItem));
  }, [characters]);

  useEffect(() => {
    updateCharacters(category.products);
  }, [category.products]);

  return (
    <div
      ref={ref}
      onClick={handleChange}
      style={{
        marginTop: "10px",
        position: "relative",
        marginRight: location.pathname === "/panel" ? "0px" : "20px",
        marginLeft: location.pathname === "/panel" ? "0px" : "20px",
      }}
    >
      {location.pathname === "/panel" && (
        <Box
          sx={{
            position: "absolute",
            zIndex: "10",
            right: "100px",
            top: "13px",
          }}
        >
          <CategoryEditModal category={category} />
        </Box>
      )}
      <Accordion
        onChange={handleChange(category.id)}
        expanded={!toggle ? expanded === category.id : toggle === category.id}
        elevation={0}
      >
        <AccordionSummary
          sx={{ backgroundColor: "#B25068", height: "64px" }}
          expandIcon={
            index !== 0 ? (
              <ArrowDropDownCircleIcon sx={{ color: "white" }} />
            ) : null
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {category.image && (
              <CardMedia
                sx={{
                  maxHeight: "64px",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                }}
                component="img"
                width="100%"
                image={`http://lavareserver.nodejs.canavardata.com/images/${category.image}`}
                alt={`${category.image}`}
              />
            )}

            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "0px",
                left: "0px",
                backgroundColor: "black",
                opacity: "0.40",
              }}
            ></Box>
            <Typography
              sx={{
                color: "white",
                fontSize: location.pathname === "/panel" ? "14px" : "20px",
                zIndex: "11",
                fontFamily: "Montserrat",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              {category.title}
            </Typography>
            {location.pathname === "/panel" && (
              <Box sx={{ display: "flex" }}>
                <Button sx={{ color: "white" }} onClick={submit}>
                  <DeleteIcon />
                </Button>
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "0px",
            backgroundColor:
              location.pathname === "/panel" && sort ? "#F5E8E4" : "",
          }}
        >
          <DragDropContext onDragEnd={sort ? handleOnDragEnd : sortAlert}>
            <Droppable droppableId="products">
              {(provided) => (
                <Box
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  {renderProducts()}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        </AccordionDetails>
        {location.pathname === "/panel" && (
          <AddProductToCategoryModal category={category} />
        )}
      </Accordion>
    </div>
  );
};

export default Category;
