import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { TextField, Input, CardMedia } from "@mui/material";
import { editProduct } from "../../actions/actionCreator";
import { uploadImage } from "../../actions/menu";
import EditIcon from "@mui/icons-material/Edit";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export default function EditProduct({ category, product }) {
  const dispatch = useDispatch();
  const { icons } = useSelector((state) => state.icons);
  const [name, setName] = useState(product.name);
  const [price, setPrice] = useState(product.price);
  const [desc, setDesc] = useState(product.desc);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState();
  const [iconNames, setIconNames] = React.useState([]);
  // const [id, setId] = useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const data = new FormData();
    const imageUrl = e.target.files[0];
    setImage(imageUrl.name);
    data.append("productImage", imageUrl);
    data.append("cat", JSON.stringify(category));
    data.append("productId", JSON.stringify(product.id));
    dispatch(uploadImage(data));
  };

  const handleIconChange = (event) => {
    const {
      target: { value },
    } = event;
    setIconNames(typeof value === "string" ? value.split(",") : value);
  };

  const handleSave = () => {
    const categoryId = category.id;
    const newProduct = {
      id: product.id,
      name,
      price,
      desc,
      image,
      icons: iconNames,
    };

    const productObject = {
      categoryId,
      newProduct,
    };
    dispatch(editProduct(productObject));
    // setName("");
    // setPrice("");
    //setDesc("");
    handleClose();
  };

  return (
    <div style={{ textAlign: "start" }}>
      <Button onClick={handleOpen}>
        <EditIcon sx={{ fontSize: "16px" }} />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ marginBottom: "20px" }}>
            <TextField
              onChange={(e) => setName(e.target.value)}
              sx={{ width: "100%", marginBottom: "15px" }}
              name="name"
              placeholder="Product Name"
              value={name}
            />
            <TextField
              onChange={(e) => setDesc(e.target.value)}
              sx={{ width: "100%", marginBottom: "15px" }}
              name="desc"
              placeholder="Product Description"
              value={desc}
            />
            <TextField
              onChange={(e) => setPrice(e.target.value)}
              sx={{ width: "100%", marginBottom: "15px" }}
              name="price"
              placeholder="Product Price"
              value={price}
            />

            <div>
              <FormControl sx={{ m: 1, width: "100%", margin: "0px" }}>
                <InputLabel id="demo-multiple-checkbox-label">Icons</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={iconNames}
                  onChange={handleIconChange}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {icons.map((icon, i) => (
                    <MenuItem key={i} value={icon}>
                      <Checkbox checked={iconNames.indexOf(icon) > -1} />
                      <ListItemText primary={icon} />
                      <CardMedia
                        key={i}
                        sx={{
                          width: "30px",
                          height: "30px",
                          objectFit: "fill",
                          margin: "5px",
                        }}
                        component="img"
                        image={`http://lavareserver.nodejs.canavardata.com/images/icons/${icon}`}
                        //alt={`${item.filename}`}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Box>
          <Input
            type="file"
            placeholder="Upload image"
            name="image"
            onChange={handleChange}
          />
          <Box>{product.image}</Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CardMedia
              sx={{
                width: "200px",
                height: "150px",
                objectFit: "fill",
                margin: "5px",
              }}
              component="img"
              image={`http://lavareserver.nodejs.canavardata.com/images/${product.image}`}
              //alt={`${item.filename}`}
            />
          </Box>
          <Button onClick={handleSave}>Edit Product</Button>
        </Box>
      </Modal>
    </div>
  );
}
