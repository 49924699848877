import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Product from "../Product";
import { CardMedia } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  maxHeight: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  paddingTop: 6,
  overflow: "scroll",
};

export default function ProductModal({ category, product, i }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div
      style={{
        position: "relative",
        width: window.innerWidth > 728 ? "24%" : "49%",
        marginTop: "5px",
      }}
    >
      <Box onClick={handleOpen}>
        <Product category={category} product={product} key={i} i={i} />
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              onClick={handleClose}
              sx={{
                position: "relative",
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "-35px",
                  right: "-10px",
                  padding: "5px",
                  border: "1px dashed gray",
                  borderRadius: "3px",
                }}
              >
                X
              </Box>
            </Box>
            <CardMedia
              component="img"
              //height="50%"
              sx={{ width: "100%" }}
              image={`http://lavareserver.nodejs.canavardata.com/images/${product.image}`}
              alt="green iguana"
            />
            <Typography
              mt={3}
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ fontFamily: "Montserrat" }}
            >
              {product.name}
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              id="transition-modal-description"
              sx={{
                mt: 2,
                fontSize: "12px",
                color: "gray",
                fontFamily: "Montserrat",
              }}
            >
              {product.desc}
            </Typography>
            <Typography
              id="transition-modal-description"
              sx={{ mt: 2, fontFamily: "Montserrat" }}
            >
              {product.price}
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
