import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { TextField, Input, CardMedia } from "@mui/material";
import { addProductToMenu } from "../../actions/actionCreator";
import { uploadImage } from "../../actions/menu";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

window.Buffer = window.Buffer || require("buffer").Buffer;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

export default function AddProductToCategoryModal({ category }) {
  const dispatch = useDispatch();
  const { menu } = useSelector((state) => state.menu);
  const { icons } = useSelector((state) => state.icons);
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [desc, setDesc] = useState("");
  const [image, setImage] = useState("");
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const [iconNames, setIconNames] = React.useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const data = new FormData();
    const imageUrl = e.target.files[0];
    setImage(imageUrl.name);
    data.append("productImage", imageUrl);
    data.append("cat", JSON.stringify(category));
    data.append("productId", JSON.stringify(id));
    dispatch(uploadImage(data));
  };

  const handleIconChange = (event) => {
    const {
      target: { value },
    } = event;
    setIconNames(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleSave = () => {
    const product = {
      id: new Date().getTime(),
      name,
      price,
      desc,
      image,
      icons: iconNames,
    };

    const addProductToCategory = () => {
      const newMenu = [...menu];
      const index = newMenu.findIndex((item) => item.id === category.id);
      newMenu[index].products.push(product);
      dispatch(addProductToMenu(newMenu));
    };

    addProductToCategory();
    setName("");
    setPrice(0);
    setDesc("");
    handleClose();
  };

  return (
    <div>
      <Button onClick={handleOpen}>Add Product</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ marginBottom: "20px" }}>
            <TextField
              onChange={(e) => setName(e.target.value)}
              sx={{ width: "100%", marginBottom: "15px" }}
              name="name"
              placeholder="Product Name"
            />
            <TextField
              onChange={(e) => setDesc(e.target.value)}
              sx={{ width: "100%", marginBottom: "15px" }}
              name="desc"
              placeholder="Product Description"
            />
            <TextField
              onChange={(e) => setPrice(e.target.value)}
              sx={{ width: "100%", marginBottom: "15px" }}
              name="price"
              placeholder="Product Price"
            />

            <div>
              <FormControl
                sx={{
                  m: 1,
                  width: "100%",
                  margin: "0px",
                  marginBottom: "10px",
                }}
              >
                <InputLabel id="demo-multiple-checkbox-label">Icons</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={iconNames}
                  onChange={handleIconChange}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {icons.map((icon, i) => (
                    <MenuItem key={i} value={icon}>
                      <Checkbox checked={iconNames.indexOf(icon) > -1} />
                      <ListItemText primary={icon} />
                      <CardMedia
                        key={i}
                        sx={{
                          width: "30px",
                          height: "30px",
                          objectFit: "fill",
                          margin: "5px",
                        }}
                        component="img"
                        image={`http://lavareserver.nodejs.canavardata.com/images/icons/${icon}`}
                        //alt={`${item.filename}`}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Input
              type="file"
              placeholder="Upload image"
              name="image"
              onChange={handleChange}
            />
          </Box>
          <Button type="submit" onClick={handleSave}>
            Add
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
