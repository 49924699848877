import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { getMenu } from "../actions/menu";
import MainTitle from "../components/MainTitle";
import Category from "../components/Category";
import { fetchInstagramFeed } from "../actions/menu";
import SideMenu from "../components/SideMenu";
import { Triangle } from "react-loader-spinner";
//import "/react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Home = () => {
  const dispatch = useDispatch();
  const { menu, loading } = useSelector((state) => state.menu);
  const { fetchFeed } = useSelector((state) => state.feedImages);
  const [lang, setLang] = useState("Tr");

  const handleMenuList = (data) => {
    setLang(data);
  };

  /*  const renderInstaFeed = () => {
    return menu.map((item, i) => {
      if (item.class === "main-title") {
        return <MainTitle key={i} info={item} feed={fetchFeed} />;
      }
    });
  }; */

  const renderList = () => {
    return menu.map((item, i) => {
      if (item.class === "category") {
        return <Category key={i} category={item} index={i} />;
      }
    });
  };

  useLayoutEffect(() => {
    // dispatch(fetchInstagramFeed());
    dispatch(getMenu({ lang }));
  }, [lang]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: window.innerWidth > 728 ? "75%" : "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#e7302a",
            width: "100%",
            textAlign: "end",
          }}
        >
          <SideMenu menu={menu} />
        </Box>
        {loading ? (
          <Triangle
            height="300"
            width="100%"
            color="#4fa94d"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        ) : (
          <Box
            sx={{
              minHeight: window.innerWidth > 728 ? "0%" : "300px",
              width: "100%",
            }}
          >
            {/*  {renderInstaFeed()} */}
          </Box>
        )}
        {renderList()}
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "30px",
          backgroundColor: "green",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
        }}
      >
        <Box
          sx={{
            color: "#4cb848",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img style={{ width: "75px" }} src="canavarlogo.png" />
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
