import * as actions from "./actionCreator";
import axios from "axios";
import alertify from "alertifyjs";

export const saveMenu = (menuData) => async (dispatch) => {
  const data = JSON.stringify(menuData);
  dispatch(actions.saveMenuLoading());
  try {
    await axios({
      method: "POST",
      url: `http://lavareserver.nodejs.canavardata.com/panel/savemenu`,
      data,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => {
        dispatch(actions.saveMenuSuccess(res.data));
        alertify.success("Menu saved successfully");
      })
      .catch((err) => {
        dispatch(actions.saveMenuFail(err, "could not posted"));
        alertify.error("Menu could not saved");
      });
  } catch (error) {
    dispatch(actions.saveMenuFail(error));
  }
};

export const getMenu = (menuData) => async (dispatch) => {
  const data = JSON.stringify(menuData);
  dispatch(actions.getMenuLoading());
  try {
    await axios({
      method: "POST",
      url: `http://lavareserver.nodejs.canavardata.com/panel/getmenu`,
      data,
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => {
        dispatch(actions.getMenuSuccess(res.data[0]));
      })
      .catch((err) => {
        dispatch(actions.getMenuFail(err, "could not get"));
      });
  } catch (error) {
    dispatch(actions.getMenuFail(error));
  }
};

export const uploadImage = (data) => async (dispatch) => {
  dispatch(actions.uploadImageLoading());
  try {
    await axios({
      method: "POST",
      url: `http://lavareserver.nodejs.canavardata.com/panel/uploadimage`,
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        dispatch(actions.uploadImageSuccess(res.data));
      })
      .catch((err) => {
        dispatch(actions.uploadImageFail(err, "could not posted"));
      });
  } catch (error) {
    dispatch(actions.uploadImageFail(error));
  }
};

export const uploadTitleImage = (data) => async (dispatch) => {
  dispatch(actions.uploadTitleImageLoading());
  try {
    await axios({
      method: "POST",
      url: `http://lavareserver.nodejs.canavardata.com/panel/uploadtitleimage`,
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        dispatch(actions.uploadTitleImageSuccess(res.data));
      })
      .catch((err) => {
        dispatch(actions.uploadTitleImageFail(err, "could not posted"));
      });
  } catch (error) {
    dispatch(actions.uploadTitleImageFail(error));
  }
};

export const uploadCategoryImage = (data) => async (dispatch) => {
  dispatch(actions.uploadCategoryImageLoading());
  try {
    await axios({
      method: "POST",
      url: `http://lavareserver.nodejs.canavardata.com/panel/uploadcategoryimage`,
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        dispatch(actions.uploadCategoryImageSuccess(res.data));
      })
      .catch((err) => {
        dispatch(actions.uploadCategoryImageFail(err, "could not posted"));
      });
  } catch (error) {
    dispatch(actions.uploadCategoryImageFail(error));
  }
};

export const sendFeedImages = (imgData) => async (dispatch) => {
  const data = JSON.stringify(imgData);
  dispatch(actions.sendFeedImagesLoading());
  try {
    await axios({
      method: "POST",
      url: `http://lavareserver.nodejs.canavardata.com/panel/sendfeedimages`,
      data,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => {
        dispatch(actions.sendFeedImagesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(actions.sendFeedImagesFail(err, "could not send"));
      });
  } catch (error) {
    dispatch(actions.sendFeedImagesFail(error));
  }
};

/* export const fetchInstagramFeed = () => async (dispatch) => {
  const instaToken =
    "IGQVJVQjEycF9kMEpTVi1vV3pIQUN5NVpQWElEQ2I5R1hzSlI2bHNzZAlZAuX0lLa0xFVy15XzRmZAWNhVGp6OFFlSlhmXy1QZATRRV1JhRFZAVZA29yUlpaS2NrUGRkcXFpM1NjTHVwNmJnMlRFVXBWRnpmRQZDZD";

  const limit = 3;
  dispatch(actions.fetchInstagramFeedLoading());
  try {
    await axios
      .get(
        `https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&limit=${limit}&access_token=${instaToken}`
      )
      .then((resp) => {
        dispatch(actions.fetchInstagramFeedSuccess(resp.data.data));
      });
  } catch (error) {
    dispatch(actions.fetchInstagramFeedFail(error));
  }
}; */

export const uploadIcons = (data) => async (dispatch) => {
  dispatch(actions.iconsUploadLoading());
  try {
    await axios({
      method: "POST",
      url: `http://lavareserver.nodejs.canavardata.com/panel/uploadicons`,
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        dispatch(actions.iconsUploadSuccess(res.data));
        alertify.success("Icons are uploaded");
      })
      .catch((err) => {
        dispatch(actions.iconsUploadFail(err, err.message));
        alertify.error("Error");
      });
  } catch (error) {
    dispatch(actions.iconsUploadFail(error));
    alertify.error("Error");
  }
};

export const getIcons = () => async (dispatch) => {
  // const data = JSON.stringify(iconsData);
  dispatch(actions.getIconsLoading());
  try {
    await axios({
      method: "GET",
      url: `http://lavareserver.nodejs.canavardata.com/panel/geticons`,
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => {
        dispatch(actions.getIconsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(actions.getIconsFail(err, err.message));
      });
  } catch (error) {
    dispatch(actions.getIconsFail(error));
  }
};
