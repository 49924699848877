import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { TextField, Input } from "@mui/material";
import { editMenuItem } from "../../actions/actionCreator";
import EditIcon from "@mui/icons-material/Edit";
import { uploadCategoryImage } from "../../actions/menu";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

export default function CategoryEditModal({ category }) {
  const dispatch = useDispatch();
  const [categoryName, setCategoryName] = useState(category.title);
  const [image, setImage] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const data = new FormData();
    const imageUrl = e.target.files[0];
    setImage(imageUrl.name);
    data.append("categoryImage", imageUrl);
    dispatch(uploadCategoryImage(data));
  };

  const handleCategory = () => {
    const editCategory = {
      id: category.id,
      class: "category",
      title: categoryName,
      products: category.products,
      image,
    };

    dispatch(editMenuItem(editCategory));
    setCategoryName("");
    handleClose();
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        <EditIcon sx={{ color: "white" }} />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ marginBottom: "20px" }}>
            <TextField
              onChange={(e) => setCategoryName(e.target.value)}
              sx={{ width: "100%", marginBottom: "15px" }}
              name="category"
              placeholder="Category Name"
              value={categoryName}
            />
            <Input
              type="file"
              placeholder="Upload image"
              name="image"
              onChange={handleChange}
            />
          </Box>
          <Button onClick={handleCategory}>Save</Button>
        </Box>
      </Modal>
    </div>
  );
}
