import { combineReducers } from "redux";
import menu from "./menu";
import auth from "./auth";
import feedImages from "./feedImages";
import icons from "./icons";

const reducers = {
  menu,
  auth,
  feedImages,
  icons,
};

const rootReducer = combineReducers(reducers);
export default rootReducer;
